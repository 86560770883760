import React from 'react'
import { Link } from "gatsby"
// import { useQueryParam, StringParam } from "use-query-params";
import "./agenda.css"

const Agend = (props) => {

  const data = props.data;
  const id = props.dataId;
  const colors = props.colors;
  const evn = n => !(n % 2);
  const speakers = props.speakers;

  return (
      <div className="session">
        <div className="row no-gutters mb-5 session-container d-flex align-items-stretch">
          
          <div 
            className="col-md-4 image-container d-none d-md-block session-container2"
            style={{backgroundImage: `url(${`../images/agenda/e${id}.jpg`})` }}
          /> 
          <div 
            className="col-md-4 image-container d-block d-md-none"
            style={{backgroundImage: `url(${`../images/carousel/c${id}.jpg`})` }}
          /> 

          <div 
            className={"col-md-8 p-4 d-flex flex-column session-container2" + ((evn(props.j))?" order-md-first":"")} 
            style={{ backgroundColor: `${colors.bgColor}`, color: `${colors.textColor}` }}
          >
            <div className="h6 small d-none">{data.time}</div>
            <div className="h1 publico-text-web mt-0 mt-lg-5" dangerouslySetInnerHTML={{__html: data.h1 }} />
            <div className="h6 mb-4 mb-sm-5" dangerouslySetInnerHTML={{__html: data.text }} />
            <div className="pl-2 row no-gutters">
              {
                speakers.map((d,i) => (
                  <div key={i} className={`position-relative pl-3 mb-3 pr-3` + ((speakers.length > 2)?` col-sm-6`:` col-12`)}>

                    <div className="hr-line" style={{ backgroundColor: `${colors.textColor}`, textDecorationColor: `${colors.textColor}`}}/>

                      <Link 
                        to={`/speakers#${d.id}`}
                        className="tospeaker"
                        style={{ backgroundColor: `${colors.textColor}`, textDecorationColor: `${colors.textColor}`}}
                        onClick={()=>{

                        }}
                      >
                        <div className="h6 small akkurat-pro-bold mb-0" style={{ color: `${colors.textColor}` }}>{d.name + ` ` + d.surname}</div>
                      </Link>

                    <div className="h6 small publico-text-web" dangerouslySetInnerHTML={{__html: d.job + `, ` + d.organisation }}  ></div>
                  
                  </div>
                ))
              }

              {
                (speakers.length===0) ? 
                  <div className={`position-relative pl-3 mb-3 pr-3` + ((speakers.length > 2)?` col-sm-6`:` col-12`)}>
                    <div className="hr-line" style={{ backgroundColor: `${colors.textColor}`, textDecorationColor: `${colors.textColor}`}}/>
                    <div className="h6 small akkurat-pro-bold mb-0" style={{ color: `${colors.textColor}` }}>{`BNY Mellon`}</div>
                  </div>

                : <div />
              }

            </div>
            
            <div className={`mt-auto row no-gutters justify-content-end` + ((props.button)?` d-flex`:` d-none`)}>
                <a 
                  href="/#"
                  onClick={(e)=>{
                    e.preventDefault();
                    {/*
                      setVid(data.link);
                    */}
                    props.setShow(data.link);
                    if(window.analytics){
                      window.analytics('send', 
                        {
                          hitType: 'event',
                          eventCategory: 'agenda',
                          eventAction: 'click',
                          eventLabel: data.h1
                        }
                      );
                    }

                  }}
                  className="play-calendar btn btn-light btn-lg btn-block medium"
                >
                  {`Play`}
                </a>
            </div>
          </div>
        </div>
      </div>
  )
  
}
export default Agend