import React, { useState, useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Agend from '../components/agenda'
import '../styles/agenda.css'

import { smoothScrollBackToTop, getDate, getStringDate, getSpeakers, COLORS } from '../utils/index.js'

import dataa from '../data/data.json';

import Player from "../components/player"
import withLocation from "../components/withLocation"


import Logolive from '../assets/logolive.svg';

const NOW = new Date();

const Agenda = ({ search }) => {
  const { video } = search

  const [show, setShow] = useState(video === undefined ? -1 : video);



  useEffect(() => {
    // console.log(show)
  });

	if (typeof window === `undefined`) {
	    return(<></>);
	}

	let colorCounter = 0;

	return(
	   <>

	  <Layout>
	    <SEO title="Agenda" description="BNY Mellon’s Future FirstSM Forum will feature an illuminating keynote by Mark Carney and a series of panels showcasing how to measure ESG impact, how to effect meaningful change, the role of market infrastructure in establishing guidelines and how to find alpha in the process."/>
        <div className="back-bg d-block" />
        <div className="back-bg2 d-block" />

	        <div id="sessions" className="container-fluid bg-softblue">
	          <div className="container sessions-container">
	   
	            <div className="mb-5 d-none d-lg-block" />

	            <div className="row pt-5 pb-5">
	              <div className="col-lg-7">
	                <h1 className="publico-text-web display-4 mb-3 color-blue">{`Agenda`}</h1>
	                <p className="h5 mb-4 color-blue">{`MAY 12, 2021 | A VIRTUAL EVENT`}</p>
	                <p className="h5-small mb-0 color-blue">{`As the adoption of ESG practices becomes the industry norm, companies will need to accurately assess ESG impact on their value and business strategy, and communicate that impact clearly. Our panels of experts from industry and academia tackle the questions and challenges of streamlining sustainability management.`}</p>
	              </div>

		          <div className="col-lg-5 d-flex justify-content-end align-items-end">
		            <a 
		            rel="noreferrer"
		              id="watch-intro" 
		              href="/pdf/F3-RecapArticle.PDF"
		              className="btn btn-darkteal btn-lg watch-intro medium"
		              onClick={(e)=>{
		                // e.preventDefault();
		              if(window.analytics){
		                window.analytics('send', 
		                  {
		                    hitType: 'event',
		                    eventCategory: 'agenda',
		                    eventAction: 'click',
		                    eventLabel: 'RECAP ARTICLE'
		                  }
		                );
		              }
		              }}
		              target="_blank" 
		            >RECAP ARTICLE</a>
		          </div>

	            </div>

{/*
	            <div className="row no-gutters mb-5 session-container d-flex position-relative" style={{ backgroundColor: `#D9E3E8`}}>
	                <div 
	                  className={"col p-4 p-lg-5 d-flex flex-column liveblock"}
	                  style={{backgroundImage: `url(${`../images/bg.svg`})` }} 
	                >
	                  <div className="mt-4 mb-4 logolive">
	                  	<Logolive/>
	                  </div>
	                  <div className="liveblock-h1 mt-1 mb-1 publico-text-web display-4 color-blue" dangerouslySetInnerHTML={{__html: `Future First<sup class="akkurat-pro">SM</sup> <span>Forum</span>`}} />
	                  <div className="liveblock-h2 mb-4 publico-text-web color-blue" dangerouslySetInnerHTML={{__html: `8:30 a.m. EDT on May 12`}} />
	                  <div className="row no-gutters d-flex justify-content-start">
	                      <a
	                        href="/#" 
	                        className="btn btn-darkteal btn-lg btn-block medium play-calendar2"
	                        onClick={(e)=>{
			                    e.preventDefault();
			                    setShow(`483197`);
								if(window.analytics){
									window.analytics('send', 
									  {
									    hitType: 'event',
									    eventCategory: 'agenda',
									    eventAction: 'click',
									    eventLabel: 'join live'
									  }
									);
								}
	                        }}
	                      >
	                        {`JOIN US LIVE NOW`}
	                      </a>
	                  </div>

	                </div>
	           	</div>
*/}            
	            {
	                dataa.sessions.map((q,j) => (
						<Agend
							key={j}
							data={q}
							colors={COLORS[(colorCounter++)%3]}
							speakers={getSpeakers(q.speakers)}
							past={getDate(q.date)<NOW}
							date={getStringDate(getDate(q.date))}
							dataId={q.id}
							button={true}
							j={j}
							setShow={setShow}
						/>
                  	))
	            }

	            <p className="h4-small mt-5 mb-0 color-blue medialink" dangerouslySetInnerHTML={{__html: `For media inquiries, please view our <a href="https://www.bnymellon.com/us/en/about-us/newsroom/media-resources.html" target="_blank">Media Resources</a>` }} />

	            <div className="d-flex justify-content-center position-relative">
	              <a 
	              id="back-to-top" 
	              href="/#" 
	              className="btn btn-darkteal btn-lg back-to-top medium mt-17 mb-17 pl-5 pr-5"

	                onClick={(e)=>{
	                  e.preventDefault();

	                  smoothScrollBackToTop();
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'agenda',
                                  eventAction: 'click',
                                  eventLabel: 'totop'
                                }
                              );
                            }


	                }}


	              >Back to top</a>
	            </div>

	          </div>
	        </div>

	  </Layout>

      <Player UUID={show} setShow={setShow}/>
    </>
	)
}

export default withLocation(Agenda)